.root {
  @apply bg-[#4138C2] text-white cursor-pointer inline-flex px-10 rounded-lg leading-6  transition ease-in-out duration-150 shadow-sm font-semibold text-center justify-center uppercase py-4 border border-transparent items-center;
}

.root:hover {
  @apply bg-indigo-800 text-white border border-white;
}

.root:focus {
  @apply outline-none ring-2 ring-pink-500 ring-opacity-50;
}

.root[data-active] {
  @apply bg-zinc-600;
}

.loading {
  @apply bg-indigo-700 text-white border-zinc-600 cursor-not-allowed;
}

.slim {
  @apply py-2 transform-none normal-case;
}

.disabled,
.disabled:hover {
  @apply text-zinc-400 border-zinc-600 bg-zinc-700 cursor-not-allowed;
  filter: grayscale(1);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}


.newone {
  @apply bg-zinc-900 text-white border border-white lowercase;
  border-radius: 200px;
  
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

/* @import  */

*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  @apply outline-none ring-2 ring-pink-500 ring-opacity-50;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
}

html,
body {
  /* font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue',
    'Helvetica', sans-serif; */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  @apply text-black bg-zinc-800 antialiased;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.height-screen-helper {
  height: calc(100vh - 80px);
}

.original-text-shadow {
  text-shadow:
  -1px -1px 0 #000,  
   1px -1px 0 #000,
   -1px 1px 0 #000,
    1px 1px 0 #000;
}

.stop-click {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  /* border:2px solid black; */
}


.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0
}
.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
            transform: translateX(-100%);
    background-image: -webkit-gradient(
      linear,
      left top, right top,
      color-stop(0, rgba(255, 255, 255, 0)),
      color-stop(20%, rgba(255, 255, 255, 0.2)),
      color-stop(60%, rgba(255, 255, 255, 0.5)),
      to(rgba(255, 255, 255, 0))
    );
    background-image: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    -webkit-animation: shimmer 3s infinite;
            animation: shimmer 3s infinite;
    content: '';
  }
@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  
}

ul.checkmarks {
  list-style-type: none;
  padding-left: 0;
}

ul.checkmarks li {
  position: relative;
  padding-left: 1.75em;
}

ul.checkmarks li::before {
  content: '✔';
  color: #4F45E4;
  position: absolute;
  left: 0.25em;
  top: 0;
}

#cursor {
  display: inline-block;
  margin-left: 2px;
  animation: blink 0.7s infinite;
}

.circular-progress-bar {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-progress-bar .progress-ring__circle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circular-progress-bar .progress-ring__circle-loaded {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circular-progress-bar .percentage {
  position: absolute;
  font-size: 10px;
  color: #4139C2;
  font-weight: bold;
}

.gradient-text{
  @apply text-transparent bg-clip-text bg-gradient-to-r from-[#3864FF] to-[#F1148B]  pt-[0.2em] -mt-[0.5em];
}

.hanno-font{
  @apply pt-[0.2em] -mt-[0.5em];
  font-family: 'Hanno Test', sans-serif;
}


.download-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%234138C2' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.special-gradient-bg {
  /* background: linear-gradient(180deg, rgba(236,235,249,1) 0%, rgba(236,235,249,1) 68%, rgba(255,255,255,1) 88%); */
}

.hp-center-text {
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #060D2166;
}
.hp-center-text:before,
.hp-center-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  border-bottom: 1px solid #0000001A;
}
.hp-center-text:before {
  left: 0;
}
.hp-center-text:after {
  right: 0;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}


.lock-screen {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}


/* width */
#scrollableDiv::-webkit-scrollbar {
  width: 4px;
}

/* Track */
#scrollableDiv::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);;
  border-radius: 1px;
}

/* Handle */
#scrollableDiv::-webkit-scrollbar-thumb {
  background: rgba(241, 20, 139,0.5);
  border-radius: 1px;
}

/* Handle on hover */
#scrollableDiv::-webkit-scrollbar-thumb:hover {
  background: #F1148B;
  opacity: 0.8;
}
/* 
@keyframes slideUp1 {
  0% {
    transform: translateY(0);
    opacity: 0.0;
  }
  100% {
    transform: translateY(-40px);
    opacity: 1.0;
  }
}



@keyframes slideUp2 {
  0% {
    transform: translateY(10px);
    opacity: 0.0;
  }
  100% {
    transform: translateY(-30px);
    opacity: 1.0;
  }
}

@keyframes slideUp3 {
  0% {
    transform: translateY(20px);
    opacity: 0.0;
  }
  100% {
    transform: translateY(-20px);
    opacity: 1.0;
  }
}

blockquote:nth-child(1) {
  animation: slideUp1 5s infinite linear 0s;
}

blockquote:nth-child(2) {
  animation: slideUp2 5s infinite linear 1s;
}

blockquote:nth-child(3) {
  animation: slideUp3 5s infinite linear 2s;
} */

@keyframes photo_strip_animation_mobile {
  0% {
    transform: translateX(-400%);
}
100% {
    transform: translateX(0);
}
  
}

.photo_strip_mobile {
  animation: photo_strip_animation_mobile 18s linear infinite;
  animation-duration: 18s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: photo_strip_animation_mobile;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
}